<template>
  <div class="dashboard__container" v-if="user">
    <div class="dashboard__container--body mb-3">
      <div class="dashboard__container--body--col ">
        <div class="whiteBack pb-5">
          <h4 class="mb-2">Background Checks</h4>
          <div class="mb-4">
            <label for="type">Background Check Status:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="responses"
              v-model="user.backgroundCheckResponse"
              @input="updateUser()"
              :clearable="true"
              >
            </v-select>
          </div>
          <div class="mb-4">
            <input type="text" v-model="orderNumber" placeholder="Enter Existing Order Number">
            <transition name="fade">
              <button v-if="orderNumber" class="mt-3 btn btn__primary btn__small" @click="enterNumber()">Submit</button>
            </transition>
          </div>
          <div class="mb-4">
          <label for="type">Place New Order:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="backgroundCheckTypes"
              v-model="type"
              >
            </v-select>
          </div>
          <button class="btn btn__primary btn__small mb-3" @click="placeOrder()" :disabled="!type">
            Place Order
            <transition name="fade">
              <span class="ml-2" v-if="performingRequestPlace">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>

          <h5 class="mt-5 mb-1">Invitations:</h5>
            <div class="caption" v-if="user.checkInvitations && user.checkInvitations[0]">
              <div v-for="(item, index) in user.checkInvitations" :key="index" class="mb-3">
                <div v-if="item.payload" class="mb-1">
                  <span class="strong" v-if="item && item.payload && item.payload.result && item.payload.applicantinfo && item.payload.applicantinfo.reference">{{item.payload.result.invitationID}} | {{item.payload.applicantinfo.reference}}</span>
                  <div v-if="activeResponse(item) && activeResponse(item)[0] && activeResponse(item)[0].payload && activeResponse(item)[0].payload[0]">Status: {{item.payload.result.status}} - {{activeResponse(item)[0].payload[0].DateCreated | moment("MM/DD/YYYY")}} - 
                    <button class="btn btn__outlined btn__xsmall" @click="resendInvite(activeResponse(item)[0].payload[0])">
                      Resend
                      <transition name="fade">
                        <span class="ml-2" v-if="performingRequestResend">
                        <i class="fa fa-spinner fa-spin"></i>
                        </span>
                      </transition>
                    </button>
                  </div>
                  
                  <div v-if="activeResponse(item) && activeResponse(item)[0] && activeResponse(item)[0].payload && activeResponse(item)[0].payload[0]">Order Number: {{activeResponse(item)[0].payload[0].OrderID}}</div>
                </div>
              </div>
            </div>
            <h5 class="mb-1">Results:</h5>
            <div class="caption" v-if="user.checkOrderResults && user.checkOrderResults[0]">
              <div v-for="(item, index) in user.checkOrderResults" :key="index" class="mb-3">
                <div v-if="item.payload" class="mb-1">
                  <span class="strong">{{item.payload.OrderID}}</span> | Status: {{item.payload.OrderStatus}} - {{item.description}}
                </div>
                <div v-else>{{item.description}}</div>
                  <ul v-if="item.payload">
                    <li v-for="(item, index) in item.payload.Searches" :key="index">
                      <div>
                        <span class="strong">{{item.SearchID}}</span> - {{item.SearchName}}
                      </div>
                      <div v-if="item.Notes">
                        {{item.Notes}}
                      </div>
                      <div>
                        Records Found: <span class="strong" :class="{ success: (item.RecordsFound == 0 || (item.RecordsFound == 1 && item.SearchName == 'SSN Trace/Address History')), danger: (item.RecordsFound != 0 && item.SearchName != 'SSN Trace/Address History') }">{{item.RecordsFound}}</span> - {{item.SearchStatus}}
                      </div>
                      <div v-if="item.ReportLink">
                         <a :href="item.ReportLink" target="_blank">{{item.ReportLink}}</a>
                      </div>
                    </li>
                  </ul>
              </div>
            </div>
            <div class="mt-0" v-if="(user.checkInvitations && user.checkInvitations[0] && user.checkInvitations[0].payload && user.checkInvitations[0].payload.applicantinfo) && (!user.checkOrderResults || !user.checkOrderResults[0])">
              <button class="btn btn__xsmall btn__outlined mt-2" @click="onTextBack()">
                Send Reminder Text
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequestBack">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </div>
            <div class="mb-0 mt-2 caption" v-if="user.updateBackgroundReminderSent">
                Last Sent: {{formatStamp(user.updateBackgroundReminderSent)}}
            </div>

            


          </div>
        </div>
        <!-- <div class="dashboard__container--body--col" v-if="user && user.checkInvitations && user.checkInvitations[0] && user.checkInvitations[0].payload">
          <div class="whiteBack">
            <h5>Invitations:</h5>
            <div class="caption">
              <div><span class="strong">ID# {{user.checkInvitations[0].payload.result.invitationID}}</span> | Status: {{user.checkInvitations[0].payload.result.status}}</div>

              <div v-if="user.checkInvitationResponses && user.checkInvitationResponses[0] && user.checkInvitationResponses[0].payload && user.checkInvitationResponses[0].payload[0]">


                <span class="strong">ID# {{user.checkInvitationResponses[0].payload[0].ID}}</span> | {{user.checkInvitationResponses[0].payload[0].Status}} - {{user.checkInvitationResponses[0].payload[0].DateCreated | moment("MM/DD/YYYY")}}
              </div>
            </div>
            <h5 class="mt-3">Results:</h5>
            <div class="caption" v-if="user.checkOrderResults[0]">
              <div v-if="user.checkOrderResults[0].payload && user.checkOrderResults[0].payload[0]"><span class="strong">{{user.checkOrderResults[0].payload[0].OrderID}}</span> | Status: {{user.checkOrderResults[0].payload[0].OrderStatus}} - {{user.checkOrderResults[0].description}}</div>
              <div v-else>{{user.checkOrderResults[0].description}}</div>
              <ul v-if="user.checkOrderResults[0].payload">
                <li v-for="item in user.checkOrderResults[0].payload.Searches">
                  <span class="strong">{{item.SearchID}}</span> - {{item.SearchName}} - {{item.SearchStatus}}
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      <div class="dashboard__container--body--col">
        <div class="whiteBack pb-5">
          <h4 class="mb-2">Drug Screenings</h4>
          <div class="mb-4">
            <label for="type">Drug Screening Status:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="responses"
              v-model="user.drugCheckResponse"
              @input="updateUser()"
              :clearable="true"
              >
            </v-select>
          </div>
          <div class="mb-4">
            <input type="text" v-model="drugOrderNumber" placeholder="Enter Order Number">
            <transition name="fade">
              <button v-if="drugOrderNumber" class="mt-3 btn btn__primary btn__small" @click="enterDrugNumber()">Submit</button>
            </transition>
          </div>
          <div class="mb-4">
          <label for="type">Place New Order:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="drugCheckTypes"
              v-model="drugtype"
              >
            </v-select>
          </div>
          <button class="btn btn__primary btn__small mb-3" @click="placeDrug()" :disabled="!drugtype">
            Place Order
            <transition name="fade">
              <span class="ml-2" v-if="performingRequestDrug">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>

          <h5 class="mt-5 mb-1">Invitations:</h5>
            <div class="caption" v-if="user.drugInvitations && user.drugInvitations[0]">
              <div v-for="(item, index) in user.drugInvitations" :key="index" class="mb-3">
                <div>
                  <div class="strong">
                    <span v-if="item.payload && item.payload.product && item.payload.product[0] && item.payload.product[0].name">{{item.payload.product[0].name}}
                     - 
                      <button class="btn btn__outlined btn__xsmall" @click="resendInvite(item)">
                        Resend
                        <transition name="fade">
                          <span class="ml-2" v-if="performingRequestResend">
                          <i class="fa fa-spinner fa-spin"></i>
                          </span>
                        </transition>
                      </button>
                    </span>
                  </div>
                </div>
                
                <div v-if="item && item.payload && activeDrugResponse(item) && activeDrugResponse(item)[0] && activeDrugResponse(item)[0].payload" class="mb-1">

                    <div class="strong">Invitation ID: {{getLast(activeDrugResponse(item)).payload[0].ID}}</div>
                    <div class="strong">Order ID: {{getLast(activeDrugResponse(item)).payload[0].OrderID}}</div>
                    <div>Applicant Email: {{getLast(activeDrugResponse(item)).payload[0].ApplicantEmail}}</div>
                    <div>Date Ordered: {{getLast(activeDrugResponse(item)).payload[0].DateCreated}}</div>
                </div>

                <!-- <div v-if="activeResponse(item) && activeResponse(item)[0] && item && item.payload && item.payload.results">Status: {{item.payload.result.status}} - {{activeResponse(item)[0].payload[0].DateCreated | moment("MM/DD/YYYY")}} - </div> -->

              </div>
            </div>
            <h5 class="mb-1">Results:</h5>
            <div class="caption" v-if="user.drugOrderResults && user.drugOrderResults[0]">
              <div v-for="(item, index) in user.drugOrderResults" :key="index" class="mb-3">
                <div v-if="item.payload" class="mb-1">
                  <span class="strong">{{item.payload.OrderID}}</span> | Status: {{item.payload.OrderStatus}} - {{item.description}}
                </div>
                <div v-else>{{item.description}}</div>
                  <ul v-if="item.payload">
                    <li v-for="(item, index) in item.payload.Searches" :key="index">
                      <div>
                        <span class="strong">{{item.SearchID}}</span> - {{item.SearchName}}
                      </div>
                      <div v-if="item.Notes">
                        {{item.Notes}}
                      </div>
                      <div>
                        Records Found: <span class="strong" :class="{ success: item.RecordsFound == 0, danger: item.RecordsFound != 0 }">{{item.RecordsFound}}</span> - {{item.SearchStatus}}
                      </div>
                      <div v-if="item.ReportLink">
                         <a :href="item.ReportLink" target="_blank">{{item.ReportLink}}</a>
                      </div>
                    </li>
                  </ul>
              </div>
            </div>

            <div class="mt-0" v-if="(user.drugInvitations && user.drugInvitations[0] && user.drugInvitations[0].payload && user.drugInvitations[0].payload.applicantinfo) && (!user.drugOrderResults || !user.drugOrderResults[1])">
              <button class="btn btn__xsmall btn__outlined mt-2" @click="onTextDrug()">
                Send Reminder Text
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequestAccurate">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </div>
            <div class="mb-0 mt-2 caption" v-if="user.updateDrugReminderSent">
                Last Sent: {{formatStamp(user.updateDrugReminderSent)}}
            </div>



          </div>
        </div>
      
      <div class="dashboard__container--body--col">
        <div class="whiteBack pb-5">
          <h4 class="mb-2">Accurate Checks</h4>
          <div class="mb-4">
            <label for="type">Accurate Check Status:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="accurateResponses"
              v-model="user.accurateCheckResponse"
              @input="updateAccurate()"
              :clearable="true"
              >
            </v-select>
          </div>
          <div class="caption mb-3 pl-2" v-if="user.accurateCheckCreated && user.accurateCheckCreated != null">
            Accurate Check Ordered: {{user.accurateCheckCreated.toDate() | moment("MM/DD/YYYY")}}
          </div>
          <div class="mb-4">
            <input type="text" class="mb-3" v-model="user.accurateCheckRef" placeholder="Reference Number" @blur="updateAccurateRef()">
            <textarea name="acc" id="acc" cols="30" rows="2" v-model="newAccurateCheckNote" placeholder="Accurate Check Notes">
            </textarea>
            <transition name="fade">
              <button v-if="newAccurateCheckNote" class="mt-3 btn btn__primary btn__small" @click="submitNote()">
                Submit
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest1">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </transition>
            <div class="mt-4 caption">
              <div v-for="(item, index) in user.accurateCheckNotes" class="pb-2" :key="index" style="border-bottom: 1px solid #ddd;">
                {{item}}
              </div>
            </div>

              <button class="btn btn__primary btn__small mb-3" @click="placeAccurate()" v-if="(user && (!user.accurateCheckResponse))">
              Place New Order
              <transition name="fade">
                <span class="ml-2" v-if="performingRequestAccurate">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>


            <div class="mt-0" v-if="(user && (user.accurateCheckResponse == 'Order Placed'))">
              <button class="btn btn__xsmall btn__outlined mt-2" @click="onTextAccurate()">
                Send Reminder Text
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequestAccurate">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </div>
            <div class="mb-0 mt-2 caption" v-if="user.updateAccurateReminderSent">
                Last Sent: {{formatStamp(user.updateAccurateReminderSent)}}
            </div>
          </div>
          <!-- <div class="mb-4">
            <label for="type">Place New Order:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="drugtypes"
              v-model="drugtype"
              >
            </v-select>
          </div> -->

        </div>
      </div>
      <div class="dashboard__container--body--col">
        <div class="whiteBack pb-5">
          <h4 class="mb-2">Amazon Badge</h4>
          <div class="mb-4">
            <label for="type">Amazon Badge Status:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="amazonResponses"
              v-model="user.amazonCheckResponse"
              @input="updateAmazon()"
              :clearable="true"
              >
            </v-select>
          </div>
          <div class="caption mb-3 pl-2" v-if="user.amazonCheckCreated && user.amazonCheckCreated != null">
            Amazon Check Ordered: {{user.amazonCheckCreated.toDate() | moment("MM/DD/YYYY")}}
          </div>
          <div class="mb-4">
            <textarea name="acc" id="acc" cols="30" rows="2" v-model="newAmazonCheckNote" placeholder="Amazon Check Notes">
            </textarea>
            <transition name="fade">
              <button v-if="newAmazonCheckNote" class="mt-3 btn btn__primary btn__small" @click="submitAmazonNote()">
                Submit
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest1">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </transition>
            <div class="mt-4 caption">
              <div v-for="(item, index) in user.amazonCheckNotes" class="pb-2" :key="index" style="border-bottom: 1px solid #ddd;">
                {{item}}
              </div>
            </div>
          </div>
          <!-- <div class="mb-4">
            <label for="type">Place New Order:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="drugtypes"
              v-model="drugtype"
              >
            </v-select>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')
import router from '@/router'

export default {
  props: ['user'],
  name: 'userBackgroundCheck',
  data: () => ({
    orderNumber: '',
    drugOrderNumber: '',
    newAccurateCheckNote: '',
    newAmazonCheckNote: '',
    performingRequest1: false,
    performingRequest2: false,
    performingRequest3: false,
    performingRequest4: false,
    performingRequestDrug: false,
    performingRequestBack: false,
    performingRequestAccurate: false,
    performingRequestPlace: false,
    performingRequestResend: false,
    performingRequestDrug9: false,
    performingRequestDrug4: false,
    performingRequestDrug6: false,
    amazonResponses: ['Order Placed', 'Passed', 'Failed Do Not Hire', 'Cancelled'],
    accurateResponses: ['Order Placed', 'Passed', 'Failed Do Not Hire', 'Cancelled'],
    statuses: ['ordered', 'cancelled'],
    responses: ['Passed', 'Needs Review', 'Failed Do Not Hire', 'Duplicate'],
    types: [
      {
        title: 'Basic Package ($7.95)',
        type: 'Basic',
        id: '11409'
      },
      // {
      //   title: 'Premium Package - 1 County Only ($17.95)',
      //   type: 'Premium',
      //   id: '11381'
      // },
      {
        title: 'Platinum Package 7 Year + All Counties ($21.95)',
        type: 'Platinum',
        id: '11380'
      },
      {
        title: 'Standard MARS Package ($16.95)',
        type: 'Mars Standard',
        id: '11525'
      },
      {
        title: 'Standard MARS Package + MVR ($20.95)',
        type: 'Mars MVR',
        id: '11526'
      }
    ],
    type: '',
    drugtype: '',
    drugtypes: [
      {
        title: '4 Panel Drug Screen (No TCH) ($24.95)',
        type: '4 Panel',
        id: '11576'
      },
      {
        title: '6 Panel Drug Screen ($26.95)',
        type: '6 Panel',
        id: '11578'
      },
      {
        title: '9 Panel Drug Screen (No TCH) ($24.95)',
        type: '9 Panel',
        id: '11577'
      },
    ],
    check: {
      status: 'Ordered',
    },
    columnsInvitations: [
      {
        label: 'Status',
        field: 'status',
      },
      {
        label: 'Status Code',
        field: 'statusCode',
      },
      {
        label: 'ID',
        field: 'invitationID',
      },
    ],
    columns: [
      {
        label: 'Created',
        field: 'created',
      },
      {
        label: 'Type',
        field: 'type',
      },
      {
        label: 'Status',
        field: 'status',
      },
      {
        label: 'Response',
        field: 'response',
      },
      {
        label: 'Submitted By',
        field: 'orderedBy',
        html: true
      }
    ]
  }),
  created() {
    this.$store.dispatch("getBackgroundCheckTypes")
    this.$store.dispatch("getDrugCheckTypes")
    this.$store.dispatch("getInvitationUpdates", this.$route.params.id);
    this.$store.dispatch("getDrugInvitationUpdates", this.$route.params.id);
  },
  // mounted() {
    
  // },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userBackgroundChecks', 'backgroundCheckTypes', 'drugCheckTypes']),
    cancelledCheckBasic() {
      return this.userBackgroundChecks.filter(check => {
        return ((check.type == 'Basic') && check.status == 'Cancelled')
      })
    },
    cancelledCheckPlatinum() {
      return this.userBackgroundChecks.filter(check => {
        return ((check.type == 'Platinum') && check.status == 'Cancelled')
      })
    },
    cancelledCheckPremium() {
      return this.userBackgroundChecks.filter(check => {
        return ((check.type == 'Premium') && check.status == 'Cancelled')
      })
    },
    cancelled9Drug() {
      return this.userBackgroundChecks.filter(check => {
        return ((check.type == 'Drug-9') && check.status == 'Cancelled')
      })
    },
    cancelled6Drug() {
      return this.userBackgroundChecks.filter(check => {
        return ((check.type == 'Drug-6') && check.status == 'Cancelled')
      })
    },
    cancelled4Drug() {
      return this.userBackgroundChecks.filter(check => {
        return ((check.type == 'Drug-4') && check.status == 'Cancelled')
      })
    },
  },
  components: {
    Loader,
  },
  methods: {
    getLast(items) {
      console.log(items[0])
      let lastElement = items.pop()
      return lastElement
    },
    formatStamp(stamp) {
      if(stamp) {
        const postedDate = new Date(stamp.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY, HH:mm:ss A')
      }
    },
    onTextDrug () {
      this.performingRequestDrug = true
      let newText = {
        name: this.user.firstName,
        phone: this.user.phone,
        userId: this.user.id,
        type: 'drugReminder'
      }
      this.$store.dispatch('updateDrugReminder', newText)
      setTimeout(() => {
          this.performingRequestDrug = false
      }, 1000)
    },
    onTextBack () {
      this.performingRequestBack = true
      let newText = {
        name: this.user.firstName,
        phone: this.user.phone,
        userId: this.user.id,
        type: 'backgroundReminder'
      }
      this.$store.dispatch('updateBackgroundReminder', newText)
      setTimeout(() => {
          this.performingRequestBack = false
      }, 1000)
    },
    onTextAccurate () {
      this.performingRequestAccurate = true
      let newText = {
        name: this.user.firstName,
        phone: this.user.phone,
        userId: this.user.id,
        type: 'accurateReminder'
      }
      this.$store.dispatch('updateAccurateReminder', newText)
      setTimeout(() => {
          this.performingRequestAccurate = false
      }, 1000)
    },
    submitAmazonNote() {
      this.performingRequest1 = true
      this.$store.dispatch("addAmazonNote", {
        user: this.user.id,
        note: this.newAmazonCheckNote
      })
      setTimeout(() => {
        this.newAmazonCheckNote = ''
        this.performingRequest1 = true
      })
    },
    submitNote() {
      this.performingRequest1 = true
      this.$store.dispatch("addAccurateNote", {
        user: this.user.id,
        note: this.newAccurateCheckNote
      })
      setTimeout(() => {
        this.newAccurateCheckNote = ''
        this.performingRequest1 = true
      })
    },
    updateAmazon() {
      if (this.user && this.user.amazonCheckResponse && this.user.amazonCheckResponse == 'Order Placed') {
        this.$store.dispatch("updateUserOrderAmazon", this.user.id)
      } else if (this.user && this.user.amazonCheckResponse && this.user.amazonCheckResponse == 'Cancelled') {
        fb.usersCollection.doc(this.user.id).update({
          amazonCheckResponse: this.user.amazonCheckResponse,
          amazonCheckCreated: null
        })
      } else {
        fb.usersCollection.doc(this.user.id).update(this.user)
      }
    }, 
    updateAccurateRef() {
      fb.usersCollection.doc(this.user.id).update(this.user)
    },
    async placeAccurate() {
      const date = new Date()

      let touch = {
        contactedById: this.userProfile.id,
        contactedByFirstName: this.userProfile.firstName,
        contactedByLastName: this.userProfile.lastName,
        method: "Accurate Check Ordered",
        notes: '',
        touched: date,
      }
      await this.$store.dispatch("addTouchLog", {
        userId: this.user.id,
        log: touch
      })

      this.performingRequestAccurate = true
      let check = {
        firstName: this.user.firstName,
        name: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        userId: this.user.id,
        phone: this.user.phone,
        type: "Accurate Alert"
      }

      await this.$store.dispatch("sendBackgroundAlert", check)
      await fb.usersCollection.doc(this.user.id).update({
        accurateCheckResponse: 'Order Placed',
        accurateCheckCreated: fb.firestore.FieldValue.serverTimestamp()
      })

      
      // let deets = {
      //   firstName: this.user.firstName,
      //   phone: this.user.phone,
      // }
      // await this.$store.dispatch("sendTheBackgroundCheckMessage", deets)


      setTimeout(() => {
        this.type = ''
        this.performingRequestAccurate = false
      }, 6000)

    },
    async updateAccurate() {
      if (this.user && this.user.accurateCheckResponse && this.user.accurateCheckResponse == 'Order Placed') {

        // const date = new Date()
        // let touch = {
        //   contactedById: this.userProfile.id,
        //   contactedByFirstName: this.userProfile.firstName,
        //   contactedByLastName: this.userProfile.lastName,
        //   method: "Accurate Check Ordered",
        //   notes: '',
        //   touched: date,
        // }
        // await this.$store.dispatch("addTouchLog", {
        //   userId: this.user.id,
        //   log: touch
        // })

        // let check = {
        //   firstName: this.user.firstName,
        //   name: this.user.firstName,
        //   lastName: this.user.lastName,
        //   email: this.user.email,
        //   userId: this.user.id,
        //   phone: this.user.phone,
        //   type: "Accurate Alert"
        // }
        // // await this.$store.dispatch("updateUserOrderAccurate", this.user.id)
        // await this.$store.dispatch("sendAccurateAlert", check)
        await fb.usersCollection.doc(this.user.id).update(this.user)

        
      } else if (this.user && this.user.accurateCheckResponse && this.user.accurateCheckResponse == 'Cancelled') {
        await fb.usersCollection.doc(this.user.id).update({
          accurateCheckResponse: this.user.accurateCheckResponse,
          // accurateCheckCreated: null
        })
      } else {
        await fb.usersCollection.doc(this.user.id).update(this.user)
      }
    },
    updateUser() {
      fb.usersCollection.doc(this.user.id).update(this.user)
    },
    activeResponse(item) {
      console.log(item)
      if (this.user && this.user.checkInvitationResponses && this.user.checkInvitationResponses[0]) {
        return this.user.checkInvitationResponses.filter(response => {
          if (response && response.payload && response.payload[0] && response.payload[0].ID && item && item.payload && item.payload.result) {
            console.log(response.payload[0].ID)
            return response.payload[0].ID == item.payload.result.invitationID
          } else if (response && response.payload && response.payload[0] && response.payload[0].ID && item && item.payload && !item.payload.result) {
            return response.payload[0].ID == item.payload.invitationID
          } else {
            return null
          }
        })
      } else {
        return null
      }
    },
    activeDrugResponse(item) {
      console.log(item)
      if (this.user && this.user.drugInvitationResponses) {
        return this.user.drugInvitationResponses.filter(response => {
          if (response && response.payload && response.payload[0] && response.payload[0].ID) {
            return response.payload[0].ID == item.payload.result.invitationID
          } else {
            return null
          }
        })
      } else {
        return null
      }
    },
    enterNumber() {
      this.$store.dispatch("getOrderUpdate", {
        OrderID: this.orderNumber,
        user: this.user.id,
      })
      this.orderNumber = ''
    },
    enterDrugNumber() {
      this.$store.dispatch("getDrugOrderUpdate", {
        OrderID: this.drugOrderNumber,
        user: this.user.id,
      })
      this.drugOrderNumber = ''
    },
    async resendInvite(thing) {
      console.log(thing)
      this.performingRequestResend = true
      let check = {
        ID: thing.ID,
        ApplicantName: this.user.firstName + ' ' + this.user.lastName,
        ApplicantEmail: this.user.email,
        ApplicantCell: this.user.email,
        userId: this.user.id,
        DateCreated: thing.DateCreated
      }
      await this.$store.dispatch("resendInvitation", check)
      setTimeout(() => {
        this.performingRequestResend = false
      }, 2000)
    },
    async placeOrder() {

      const date = new Date()
      let touch = {
        contactedById: this.userProfile.id,
        contactedByFirstName: this.userProfile.firstName,
        contactedByLastName: this.userProfile.lastName,
        method: "Background Check Ordered",
        notes: '',
        touched: date,
      }
      await this.$store.dispatch("addTouchLog", {
        userId: this.user.id,
        log: touch
      })


      this.performingRequestPlace = true
      let check = {
        firstName: this.user.firstName,
        name: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        userId: this.user.id,
        packageid: this.type.Id,
        phone: this.user.phone,
        type: "Background Alert"
      }
      await this.$store.dispatch("orderCheck", check)
      await this.$store.dispatch("sendBackgroundAlert", check)

      


      // let deets = {
      //   firstName: this.user.firstName,
      //   phone: this.user.phone,
      // }
      // await this.$store.dispatch("sendTheBackgroundCheckMessage", deets)


      setTimeout(() => {
        this.type = ''
        this.performingRequestPlace = false
      }, 6000)
      
    },
    async placeDrug() {

      const date = new Date()
      let touch = {
        contactedById: this.userProfile.id,
        contactedByFirstName: this.userProfile.firstName,
        contactedByLastName: this.userProfile.lastName,
        method: "Drug Screening Ordered",
        notes: '',
        touched: date,
      }
      await this.$store.dispatch("addTouchLog", {
        userId: this.user.id,
        log: touch
      })

      this.performingRequestDrug = true
      let check = {
        firstName: this.user.firstName,
        name: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        userId: this.user.id,
        packageid: this.drugtype.Id,
        phone: this.user.phone,
        type: "Drug Alert"
      }
      console.log(check)
      await this.$store.dispatch("orderDrug", check)
      await this.$store.dispatch("sendDrugAlert", check)
      setTimeout(() => {
        this.drugtype = ''
        this.performingRequestDrug = false
      }, 6000)
    },
    // orderBasic() {
    //   let check = {
    //     firstName: this.user.firstName,
    //     lastName: this.user.lastName,
    //     email: this.user.email,
    //     userId: this.user.id,
    //     packageid: "11409"
    //   }
    //   this.$store.dispatch("orderCheck", check)
    // },
    // onRowClick(params) {
    //   console.log(params)
    //   let url = `/users/` + this.user.id + `/backgroundcheck/` + params.row.id
    //   router.push(url)
    // },
    async orderAmazon() {
      this.performingRequest4 = true

      

      let check = {
        orderedBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
        firstName: this.user.firstName,
        name: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        userId: this.user.id,
        type: 'Amazon',
        phone: this.user.phone,
        status: 'Requested'
      }
      
      await fb.usersCollection.doc(this.user.id).update({
        amazonStatus: 'Requested'
      })
      await this.$store.dispatch("sendAccurateAlert", check)
      this.performingRequest4 = false
    },
    // async orderBasic() {
    //   this.performingRequest2 = true
    //   let check = {
    //     orderedBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
    //     userId: this.user.id,
    //     user: this.user,
    //     type: 'Basic',
    //     status: 'Requested'
    //   }
    //   await fb.backgroundChecksCollection.add(check)
    //   fb.usersCollection.doc(this.user.id).update({
    //     backgroundBasicCheckStatus: 'Requested'
    //   })
    //   this.performingRequest2 = false
    // },
    async orderPremium() {
      this.performingRequest2 = true
      let check = {
        orderedBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
        userId: this.user.id,
        user: this.user,
        type: 'Premium',
        status: 'Requested'
      }
      await fb.backgroundChecksCollection.add(check)
      fb.usersCollection.doc(this.user.id).update({
        backgroundCheckStatus: 'Requested'
      })
      this.performingRequest2 = false
    },
    async orderPlatinum() {
      this.performingRequest2 = true
      let check = {
        orderedBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
        userId: this.user.id,
        user: this.user,
        type: 'Platinum',
        status: 'Requested'
      }
      await fb.backgroundChecksCollection.add(check)
      fb.usersCollection.doc(this.user.id).update({
        backgroundPlatinumCheckStatus: 'Requested'
      })
      this.performingRequest2 = false
    },
    async orderDrug4() {
      this.performingRequestDrug4 = true
      let check = {
        orderedBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
        userId: this.user.id,
        user: this.user,
        type: 'Drug-4',
        status: 'Requested'
      }
      await fb.backgroundChecksCollection.add(check)
      fb.usersCollection.doc(this.user.id).update({
        drugCheck4Status: 'Requested',
        drugCheck4Requested: true
      })
      this.performingRequestDrug4 = false
    },
    async orderDrug9() {
      this.performingRequestDrug9 = true
      let check = {
        orderedBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
        userId: this.user.id,
        user: this.user,
        type: 'Drug-9',
        status: 'Requested'
      }
      await fb.backgroundChecksCollection.add(check)
      fb.usersCollection.doc(this.user.id).update({
        drugCheck9Status: 'Requested',
        drugCheck9Requested: true
      })
      this.performingRequestDrug9 = false
    },
    async orderDrug6() {
      this.performingRequestDrug6 = true
      let check = {
        orderedBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
        userId: this.user.id,
        user: this.user,
        type: 'Drug-6',
        status: 'Requested'
      }
      await fb.backgroundChecksCollection.add(check)
      fb.usersCollection.doc(this.user.id).update({
        drugCheck6Status: 'Requested',
        drugCheck6Requested: true
      })
      this.performingRequestDrug6 = false
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
    deleteItem (item) {
      fb.backgroundChecksCollection.doc(item.id).delete()
    },
  },
  beforeDestroy() {
    this.performingRequest3 = null
    delete this.performingRequest3
    this.performingRequest2 = null
    delete this.performingRequest2
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest4 = null
    delete this.performingRequest4
    this.performingRequest6 = null
    delete this.performingRequest6
    this.performingRequestPlace = null
    delete this.performingRequestPlace
    this.performingRequestResend = null
    delete this.performingRequestResend
    this.newAccurateCheckNote = null
    delete this.newAccurateCheckNote
    this.accurateResponses = null
    delete this.accurateResponses
    this.amazonResponses = null
    delete this.amazonResponses
    this.performingRequestBack = null
    delete this.performingRequestBack
    this.performingRequestAccurate = null
    delete this.performingRequestAccurate
    this.$store.dispatch("clearBackgroundCheckTypes")
    this.$store.dispatch("clearDrugCheckTypes")
    
    this.check = null
    delete this.check
    this.columns = null
    delete this.columns
    this.statuses = null
    delete this.statuses
  }
}

</script>